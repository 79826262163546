//
// Login 1
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import "../../../init";

.login.login-1 {
  // Form modes
  .login-signin,
  .login-signup,
  .login-forgot {
    display: none;
  }

  &.login-signin-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: block;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-signup-on {
    .login-signup {
      display: block;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-forgot-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: block;
    }
  }
}

// Desktop view
@include media-breakpoint-up(lg) {
  .login.login-1 {
    .login-aside {
      width: 100%;
      max-width: 800px;
    }

    .login-form {
      width: 100%;
      max-width: 400px;
    }
  }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
  .login.login-1 {
    .login-aside {
      width: 100%;
      max-width: 400px;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .login.login-1 {
    .login-aside {
      height: 30%;
    }
    .login-form {
      width: 100%;
      max-width: 350px;
    }
  }
}

// Mobile mode
@include media-breakpoint-down(xs) {
  .login.login-1 {
    // .custom-background {
    // 	background-color: rgba(17, 17, 26, 0.8);
    // 	border-radius: 12px;
    // 	box-shadow: rgba(17, 17, 26, 1) 8px 8px 24px,
    // 		rgba(17, 17, 26, 1) 8px 16px 56px,
    // 		rgba(17, 17, 26, 1) 8px 24px 80px;
    // }
    .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
}
